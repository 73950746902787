import Mock from 'mockjs'
import { getResult } from './utils.js'
//柱状图
Mock.mock(/\/bar/, 'get', (res) => {
  const data = {
    "categories": [
      "苹果",
      "三星",
      "小米",
      "oppo",
      "vivo"
    ],
    "series": [{
      "name": "手机品牌",
      "data": [
        1000879,
        3400879,
        2300879,
        5400879,
        3400879
      ]
    }]
  }
  return getResult(data)
})
//折线图
Mock.mock(/\/line/, 'get', () => {
  const data = {
    "categories": [
      "苹果",
      "三星",
      "小米",
      "oppo",
      "vivo"
    ],
    "series": [{
      "name": "手机品牌",
      "data": [
        1000879,
        3400879,
        2300879,
        5400879,
        3400879
      ]
    }]
  }
  return getResult(data)
})
//饼图图
Mock.mock(/\/pie/, 'get', () => {
  const data = [{
    "name": "苹果",
    "value": 1000879,
    "url": "http://www.baidu.com"
  }, {
    "name": "三星",
    "value": 3400879,
    "url": "http://www.baidu.com"
  }, {
    "name": "小米",
    "value": 2300879,
    "url": "http://www.baidu.com"
  }, {
    "name": "oppo",
    "value": 5400879,
    "url": "http://www.baidu.com"
  }, {
    "name": "大疆",
    "value": 3000,
    "url": "http://www.baidu.com"
  }, {
    "name": "抖音",
    "value": 2000,
    "url": "http://www.baidu.com"
  }]
  return getResult(data)
})
//表格1
Mock.mock(/\/table1/, 'get', () => {
  const data = [
    {
      "jg": "开源中国",
      "je": "12345/2333",
      "sy": "<span>-26%</span>/+5%",
      "yx": "12345/2333",
      "jnc": "-26%/+5%",
      "jl": "smallwei"
    },
    {
      "jg": "开源中国",
      "je": "12345/2333",
      "sy": "<span>-26%</span>/+5%",
      "yx": "12345/2333",
      "jnc": "-26%/+5%",
      "jl": "smallwei"
    },
    {
      "jg": "开源中国",
      "je": "12345/2333",
      "sy": "<span>-26%</span>/+5%",
      "yx": "12345/2333",
      "jnc": "-26%/+5%",
      "jl": "smallwei"
    },
    {
      "jg": "开源中国",
      "je": "12345/2333",
      "sy": "<span>-26%</span>/+5%",
      "yx": "12345/2333",
      "jnc": "-26%/+5%",
      "jl": "smallwei"
    },
    {
      "jg": "开源中国",
      "je": "12345/2333",
      "sy": "<span>-26%</span>/+5%",
      "yx": "12345/2333",
      "jnc": "-26%/+5%",
      "jl": "smallwei"
    },
    {
      "jg": "开源中国",
      "je": "12345/2333",
      "sy": "<span>-26%</span>/+5%",
      "yx": "12345/2333",
      "jnc": "-26%/+5%",
      "jl": "smallwei"
    },
    {
      "jg": "开源中国",
      "je": "12345/2333",
      "sy": "<span>-26%</span>/+5%",
      "yx": "12345/2333",
      "jnc": "-26%/+5%",
      "jl": "smallwei"
    },
    {
      "jg": "开源中国",
      "je": "12345/2333",
      "sy": "<span>-26%</span>/+5%",
      "yx": "12345/2333",
      "jnc": "-26%/+5%",
      "jl": "smallwei"
    },
    {
      "jg": "开源中国",
      "je": "12345/2333",
      "sy": "<span>-26%</span>/+5%",
      "yx": "12345/2333",
      "jnc": "-26%/+5%",
      "jl": "smallwei"
    },
    {
      "jg": "开源中国",
      "je": "12345/2333",
      "sy": "<span>-26%</span>/+5%",
      "yx": "12345/2333",
      "jnc": "-26%/+5%",
      "jl": "smallwei"
    },
    {
      "jg": "开源中国",
      "je": "12345/2333",
      "sy": "<span>-26%</span>/+5%",
      "yx": "12345/2333",
      "jnc": "-26%/+5%",
      "jl": "smallwei"
    },
    {
      "jg": "开源中国",
      "je": "12345/2333",
      "sy": "<span>-26%</span>/+5%",
      "yx": "12345/2333",
      "jnc": "-26%/+5%",
      "jl": "smallwei"
    },
    {
      "jg": "开源中国",
      "je": "12345/2333",
      "sy": "<span>-26%</span>/+5%",
      "yx": "12345/2333",
      "jnc": "-26%/+5%",
      "jl": "smallwei"
    },
    {
      "jg": "开源中国",
      "je": "12345/2333",
      "sy": "<span>-26%</span>/+5%",
      "yx": "12345/2333",
      "jnc": "-26%/+5%",
      "jl": "smallwei"
    },
    {
      "jg": "开源中国",
      "je": "12345/2333",
      "sy": "<span>-26%</span>/+5%",
      "yx": "12345/2333",
      "jnc": "-26%/+5%",
      "jl": "smallwei"
    },
    {
      "jg": "开源中国",
      "je": "12345/2333",
      "sy": "<span>-26%</span>/+5%",
      "yx": "12345/2333",
      "jnc": "-26%/+5%",
      "jl": "smallwei"
    }
  ]
  return getResult(data)
})
//表格2
Mock.mock(/\/table2/, 'get', () => {
  const data = [{
    name: "smallwei",
    sj: "60",
    type: "抵押",
    je: "32400",
    jg: "开源中国",
    jl: "smallwei"
  }, {
    name: "smallwei",
    sj: "60",
    type: "抵押",
    je: "32400",
    jg: "开源中国",
    jl: "smallwei"
  }, {
    name: "smallwei",
    sj: "60",
    type: "抵押",
    je: "32400",
    jg: "开源中国",
    jl: "smallwei"
  }, {
    name: "smallwei",
    sj: "60",
    type: "抵押",
    je: "32400",
    jg: "开源中国",
    jl: "smallwei"
  }, {
    name: "smallwei",
    sj: "60",
    type: "抵押",
    je: "32400",
    jg: "开源中国",
    jl: "smallwei"
  }, {
    name: "smallwei",
    sj: "60",
    type: "抵押",
    je: "32400",
    jg: "开源中国",
    jl: "smallwei"
  }, {
    name: "smallwei",
    sj: "60",
    type: "抵押",
    je: "32400",
    jg: "开源中国",
    jl: "smallwei"
  }, {
    name: "smallwei",
    sj: "60",
    type: "抵押",
    je: "32400",
    jg: "开源中国",
    jl: "smallwei"
  }]
  return getResult(data)
})
//翻牌器
Mock.mock(/\/table3/, 'get', () => {
  const data = [{
    "suffixText": "申请户数(时)",
    "data": 2333
  }, {
    "suffixText": "申请户数(时)",
    "data": 2333
  }, {
    "suffixText": "申请户数(时)",
    "data": 2333
  }, {
    "suffixText": "申请户数(时)",
    "data": 2333
  }, {
    "suffixText": "申请户数(时)",
    "data": 2333
  }, {
    "suffixText": "申请户数(时)",
    "data": 2333
  }]
  return getResult(data)
})
//表格4
Mock.mock(/\/table4/, 'get', () => {
  const data = [{
    "suffixText": "申请户数(时)",
    "data": 2333
  }, {
    "suffixText": "申请户数(时)",
    "data": 2333
  }, {
    "suffixText": "申请户数(时)",
    "data": 2333
  }, {
    "suffixText": "申请户数(时)",
    "data": 2333
  }, {
    "suffixText": "申请户数(时)",
    "data": 2333
  }, {
    "suffixText": "申请户数(时)",
    "data": 2333
  }]
  return getResult(data)
})

//字符云
Mock.mock(/\/wordCloud/, 'get', () => {
  const data = [{
    name: '三星',
    value: '1234'
  }, {
    name: '小米',
    value: '1234'
  }, {
    name: '华为',
    value: '1234'
  }, {
    name: 'oppo',
    value: '1234'
  }, {
    name: '抖音',
    value: '1234'
  }, {
    name: '快手',
    value: '1234'
  }, {
    name: '淘宝',
    value: '1234'
  }, {
    name: '百度',
    value: '1234'
  }, {
    name: '京东',
    value: '1234'
  }, {
    name: '天猫',
    value: '1234'
  }, {
    name: '字符1',
    value: '1234'
  }, {
    name: '字符1',
    value: '1234'
  }]
  return getResult(data)
})
//象型图
Mock.mock(/\/pictorialBar/, 'get', () => {
  const data = {
    "categories": [
      "苹果",
      "三星",
      "小米",
      "oppo",
      "vivo"
    ],
    "series": [{
      "name": "手机品牌",
      "data": [
        1000879,
        3400879,
        2300879,
        5400879,
        3400879
      ]
    }]
  }
  return getResult(data)
})
//雷达图
Mock.mock(/\/radar/, 'get', () => {
  const data = {
    indicator: [{
      name: '销售',
      max: 6500
    },
    {
      name: '管理',
      max: 16000
    },
    {
      name: '信息技术',
      max: 30000
    },
    {
      name: '客服',
      max: 38000
    },
    {
      name: '研发',
      max: 52000
    },
    {
      name: '市场',
      max: 25000
    }
    ],
    series: [{
      data: [{
        value: [4300, 10000, 28000, 35000, 50000, 19000],
        name: '预算分配（Allocated Budget）'
      },
      {
        value: [5000, 14000, 28000, 31000, 42000, 21000],
        name: '实际开销（Actual Spending）'
      }
      ]
    }]
  }
  return getResult(data)
})
//散点图
Mock.mock(/\/scatter/, 'get', () => {
  const data = [{
    "data": [
      [1, 8.04],
      [2, 6.95]
    ]
  },
  {
    "data": [
      [1, 4.04],
      [2, 3.95]
    ]
  }]
  return getResult(data)
})
//仪表盘
Mock.mock(/\/gauge/, 'get', () => {
  const data = {
    min: 1,
    max: 10,
    label: '名称',
    value: 4,
    unit: '%'
  }
  return getResult(data)
})
//轮播图
Mock.mock(/\/swiper/, 'get', () => {
  const data = [
    {
      value: 'https://img.alicdn.com/tfs/TB1v28TC8v0gK0jSZKbXXbK2FXa-1880-640.jpg',
    }, {
      value: 'https://img.alicdn.com/tfs/TB1uevcCrj1gK0jSZFuXXcrHpXa-1880-640.jpg',
    }]
  return getResult(data)
})
//漏斗图
Mock.mock(/\/funnel/, 'get', () => {
  const data = [{
    value: 335,
    name: '直接访问'
  },
  {
    value: 310,
    name: '邮件营销'
  },
  {
    value: 234,
    name: '联盟广告'
  }]
  return getResult(data)
})

//面积图
Mock.mock(/\/area/, 'get', (res) => {
  const data = {
    "categories": [
      "苹果11111",
      "三星",
      "小米",
      "oppo",
      "vivo"
    ],
    "series": [{
      "name": "手机品牌",
      "stack": "phone",
      "data": [
        1000879,
        3400879,
        2300879,
        5400879,
        3400879
      ]
    },{
      "name": "手机价格",
      "stack": "phone",
      "data": [
        1200879,
        3100879,
        2100879,
        5100879,
        3100879
      ]
    }]
  }
  return getResult(data)
})


//热力图 + 百度地图
Mock.mock(/\/heatandbdmap/, 'get', (res) => {
  const data = {
    "categories": [
      "苹果123",
      "三星",
      "小米",
      "oppo",
      "vivo"
    ],
    "series": [{
      "name": "手机品牌",
      "stack": "phone",
      "data": [
        1000879,
        3400879,
        2300879,
        5400879,
        3400879
      ]
    },{
      "name": "手机价格",
      "stack": "phone",
      "data": [
        1200879,
        3100879,
        2100879,
        5100879,
        3100879
      ]
    }]
  }
  return getResult(data)
})


//香港人口密度
Mock.mock(/\/hkpopulationdensity/, 'get', (res) => {
  const data = {
    name: '香港18区人口密度',
    cityName: 'HK',
    data: [
      { name: '中西区', value: 20057.34 },
      { name: '湾仔', value: 15477.48 },
      { name: '东区', value: 31686.1 },
      { name: '南区', value: 6992.6 },
      { name: '油尖旺', value: 44045.49 },
      { name: '深水埗', value: 40689.64 },
      { name: '九龙城', value: 37659.78 },
      { name: '黄大仙', value: 45180.97 },
      { name: '观塘', value: 55204.26 },
      { name: '葵青', value: 21900.9 },
      { name: '荃湾', value: 4918.26 },
      { name: '屯门', value: 5881.84 },
      { name: '元朗', value: 4178.01 },
      { name: '北区', value: 2227.92 },
      { name: '大埔', value: 2180.98 },
      { name: '沙田', value: 9172.94 },
      { name: '西贡', value: 3368 },
      { name: '离岛', value: 806.98 }
    ],
    nameMap: {
      'Central and Western': '中西区',
      Eastern: '东区',
      Islands: '离岛',
      'Kowloon City': '九龙城',
      'Kwai Tsing': '葵青',
      'Kwun Tong': '观塘',
      North: '北区',
      'Sai Kung': '西贡',
      'Sha Tin': '沙田',
      'Sham Shui Po': '深水埗',
      Southern: '南区',
      'Tai Po': '大埔',
      'Tsuen Wan': '荃湾',
      'Tuen Mun': '屯门',
      'Wan Chai': '湾仔',
      'Wong Tai Sin': '黄大仙',
      'Yau Tsim Mong': '油尖旺',
      'Yuen Long': '元朗'
    }
  }
  return getResult(data)
})




//从上到下树状图
Mock.mock(/\/treevertical/, 'get', (res) => {
  const data = {
    name: '从上到下树状图',
    data: {
      "name": "flare",
      "children": [
       {
        "name": "analytics",
        "children": [
         {
          "name": "cluster",
          "children": [
           {"name": "AgglomerativeCluster", "value": 3938},
           {"name": "CommunityStructure", "value": 3812},
           {"name": "HierarchicalCluster", "value": 6714},
           {"name": "MergeEdge", "value": 743}
          ]
         },
         {
          "name": "graph",
          "children": [
           {"name": "BetweennessCentrality", "value": 3534},
           {"name": "LinkDistance", "value": 5731},
           {"name": "MaxFlowMinCut", "value": 7840},
           {"name": "ShortestPaths", "value": 5914},
           {"name": "SpanningTree", "value": 3416}
          ]
         },
         {
          "name": "optimization",
          "children": [
           {"name": "AspectRatioBanker", "value": 7074}
          ]
         }
        ]
       },
       {
        "name": "animate",
        "children": [
         {"name": "Easing", "value": 17010},
         {"name": "FunctionSequence", "value": 5842},
         {
          "name": "interpolate",
          "children": [
           {"name": "ArrayInterpolator", "value": 1983},
           {"name": "ColorInterpolator", "value": 2047},
           {"name": "DateInterpolator", "value": 1375},
           {"name": "Interpolator", "value": 8746},
           {"name": "MatrixInterpolator", "value": 2202},
           {"name": "NumberInterpolator", "value": 1382},
           {"name": "ObjectInterpolator", "value": 1629},
           {"name": "PointInterpolator", "value": 1675},
           {"name": "RectangleInterpolator", "value": 2042}
          ]
         },
         {"name": "ISchedulable", "value": 1041},
         {"name": "Parallel", "value": 5176},
         {"name": "Pause", "value": 449},
         {"name": "Scheduler", "value": 5593},
         {"name": "Sequence", "value": 5534},
         {"name": "Transition", "value": 9201},
         {"name": "Transitioner", "value": 19975},
         {"name": "TransitionEvent", "value": 1116},
         {"name": "Tween", "value": 6006}
        ]
       },
      ]
     }
  }
  return getResult(data)
})


//基础仪表盘
Mock.mock(/\/baseinstrumentpanel/, 'get', (res) => {
  const data = {
    "series": [
      {
        name: 'Pressure',
        type: 'gauge',
        detail: {
          formatter: '{value}'
        },
        data: [
          {
            value: 50,
            name: 'SCORE'
          }
        ]
      }
    ]
  }
  return getResult(data)
})



//旭日图
Mock.mock(/\/risingsunmap/, 'get', (res) => {
  const data = {
    "title": "旭日图",
    "data": [
      {
        "name": 'Flora',
        "itemStyle": {
          "color": '#da0d68'
        },
        "children": [
          {
            "name": 'Black Tea',
            "value": 1,
            "itemStyle": {
              "color": '#975e6d'
            }
          },
          {
            "name": 'Floral',
            "itemStyle": {
              "color": '#e0719c'
            },
            "children": [
              {
                "name": 'Chamomile',
                "value": 1,
                "itemStyle": {
                  "color": '#f99e1c'
                }
              },
              {
                "name": 'Rose',
                "value": 1,
                "itemStyle": {
                  "color": '#ef5a78'
                }
              },
              {
                "name": 'Jasmine',
                "value": 1,
                "itemStyle": {
                  "color": '#f7f1bd'
                }
              }
            ]
          }
        ]
      }
    ]
  }
  return getResult(data)
})


//buildings
Mock.mock(/\/buildings/, 'get', (res) => {
  const data = {
    geoJsonUrl : 'http://116.131.8.38:9000/rongcheng-file/upload/20230325/d682b2bf3dbb4e660881f111887382b5.json'
  }
  return getResult(data)
})

//outhtml
Mock.mock(/\/outhtml/, 'get', (res) => {
  const data = {
    "outHtmlUrl": "/",
  }
  return getResult(data)
})
