// import { Loading } from 'element-ui';
import { checkUrl } from '@/utils/utils'
import { getToken } from '@/utils/auth'
import {Base64} from 'js-base64';
import axios from 'axios';
window.$glob = {
  url: '',
  params: {},
  query: {},
  header: {}
};
function getGlobParams () {
  var query = window.location.search.substring(1);
  query = query.split("&");
  query.forEach(ele => {
    var pair = ele.split("=");
    window.$glob.params[pair[0]] = pair[1]
  })
}
getGlobParams();
axios.defaults.timeout = 10000;
//返回其他状态吗
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500; // 默认的
};
//跨域请求，允许保存cookie
// let loadingInstance = '';
axios.defaults.withCredentials = true;
axios.interceptors.request.use(config => {
  // loadingInstance = Loading.service({
  //   text: '拼命加载中',
  //   background: 'rgba(0,0,0,0)',
  //   spinner: 'el-icon-loading'
  // });
  const meta = (config.meta || {});
  const isToken = meta.isToken === false;

  if (!checkUrl(config.url)) config.url = window.$glob.url + config.url;
  let header = window.$glob.header || {};
  config.headers = Object.assign(config.headers, header);
  config.headers['Authorization'] = `Basic ${Base64.encode(`${$website.clientId}:${$website.clientSecret}`)}`;
  //让每个请求携带token
  if (getToken() && !isToken) {
    config.headers[$website.tokenHeader] = 'bearer ' + getToken()
  }
  //获取全局参数
  if (typeof (config.data) === 'object') {
    let data = window.$glob.query || {}
    let key;
    if (config.method == 'get') {
      key = "params"
    } else if (config.method == 'post') {
      key = "data"
    }
    config[key] = Object.assign(config[key], data)
  }
  return config
}, error => {
  return Promise.reject(error)
});
//HTTPrequest拦截
axios.interceptors.response.use(config => {
  // loadingInstance.close();
  //获取状态码
  const status = config.data.code || config.status;
  if (status === 401){
    window.location.href = 'http://dateview.hbxahy.com/user/#/login'
  }
  return config;
}, error => {
  // loadingInstance.close();
  return Promise.reject(new Error(error));
})

export default axios;
